.enter {
  background: url('../../assets/images/secondary-background.png') no-repeat;
  display: flex;
  height: 100%;
  justify-content: center;
  background-size: contain;
  background-position-x: center;
  width: 100%;

  &__container {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    &__logo {
      width: 442px;
      height: 249px;
      margin-bottom: 54px;
    }

    &__button {
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      text-transform: uppercase;
      letter-spacing: -0.07em;
      width: 267px;
      height: 43px;
      border: 3px solid #ffb600;
      background: #ffb600;
      cursor: pointer;
      border-radius: 20px;
      -webkit-padding-after: 0;

      &__without-id {
        padding: 10px;
      }
    }
  }
}

@media (max-width: 390px) {
  .enter {
    &__container {
      &__logo {
        width: 302px;
        height: 168px;
      }
    }
  }
}
