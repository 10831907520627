.cookies-alert {
    width: 100%;
    position: absolute;
    padding: 17px 0 17px 0;
    color: white;
    z-index: 2;
    background: #363636;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;

    &__text {
        font-family: 'Inter', sans-serif;
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        text-transform: uppercase;
        margin: 0;
        letter-spacing: 0.06em;
        color: #FFFFFF;
    }

    &__buttons {
        display: flex;
        gap: 10px;

        .accept {
            background-color: #FFB600;
        }

        .decline {
            background: transparent;
            color: #FFFFFF;
        }

        &__button {
            border-radius: 20px;
            height: 33px;
            display: flex;
            align-items: center;
            padding: 7px 24px;
            text-transform: uppercase;
            cursor: pointer;
            border: 3px solid #FFB600;
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: -0.07em;
        }
    }
}

@media (max-width: 420px) {
    .cookies-alert {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__text {
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            width: 342px;
            margin-bottom: 14px;
        }
    }
}