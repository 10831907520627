.claim-prize {
  background: url('../../assets/images/secondary-background.png') no-repeat;
  display: flex;
  height: 100%;
  justify-content: center;
  background-size: contain;
  background-position-x: center;
  width: 100%;

  &__container {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    &__logo {
      width: 100%;
      max-width: 442px;
      min-width: 302px;
      margin-bottom: 62px;
    }

    &__button {
      display: flex;
      width: 144.303px;
      height: 40.929px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      background-color: transparent;
      border: 3px solid #ffb600;
      color: white;
      border-radius: 30px;
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: -0.07em;
      -webkit-padding-after: 0;
      margin: 41px 0 0 0;
    }

    &__body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__input {
      background-color: transparent;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 3px solid #fff;
      color: #fff;
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      margin: 0 0 32px 0;

      ::placeholder {
        padding: 10px;
      }
    }

    &__dropdown {
      background-color: transparent;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 3px solid #fff;
      color: #fff;
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      width: 100%;

      &__option {
        background-color: black;
      }
    }
  }
}
