.sweepstakes {
  &-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 84px;
    margin: 75px 110px;

    &__left {
      &__form {
        display: none !important;
      }
    }

    &__left,
    &__right {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__form {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;

        &__input {
          background-color: transparent;
          border-top: 0;
          border-left: 0;
          border-right: 0;
          border-bottom: 3px solid #fff;
          color: #fff;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
          margin: 0 0 32px 0;
        }

        &__input:last-child {
          margin: 0 0 28px 0;
        }

        &__checkbox__container {
          display: flex;
          justify-content: flex-start;

          &__checkbox {
            width: 18px;
            height: 18px;
            -webkit-appearance: none;
            appearance: none;

            border: 2px solid #fff;
            border-radius: 3px;
            margin: 0 11px 0 0;
            padding: 8px;
            display: grid;
            place-content: center;
            transform: translateY(-0.075em);
          }

          &__checkbox::before {
            content: '';
            width: 0.65em;
            height: 0.65em;
            transform: scale(0);
            box-shadow: inset 1em 1em var(--form-control-color);
            clip-path: polygon(
              14% 44%,
              0 65%,
              50% 100%,
              100% 16%,
              80% 0%,
              43% 62%
            );
            background-color: #fff;
          }

          &__checkbox:checked::before {
            transform: scale(1);
          }

          &__text {
            color: #fff;
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            text-transform: uppercase;
          }
        }
      }
    }

    &__left__header,
    &__right__header {
      text-align: center;
      margin-bottom: 73px;

      > * {
        margin: 0;
      }
    }

    &__left__header {
      display: none;
    }

    &__button {
      display: flex;
      width: 144.303px;
      height: 40.929px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      background-color: transparent;
      border: 3px solid #ffb600;
      color: white;
      border-radius: 30px;
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: -0.07em;
      -webkit-padding-after: 0;
      margin: 41px 0 0 0;
    }
  }

  &__logo {
    width: 100%;
    max-width: 442px;
    min-width: 302px;
    margin-bottom: 66px;
  }

  &__primary-title {
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-transform: capitalize;
  }

  &__secondary-title {
    color: #ffb600;
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }

  &__tertiary-title {
    color: #ffb600;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 2px;
  }

  &__data-text {
    display:inline-block;
    justify-content: center;
    align-items: center;
    color: #ffb600;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
    margin-top: 16px;

    img {
      margin-right: 2px;
    }
  }

  &__company-logos {
    width: 100%;
    max-width: 580px;
    margin-top: 77px;
  }
}

@media screen and (max-width: 768px) {
  .sweepstakes {
    &-container {
      display: flex;
      flex-direction: column;
      margin: 90px 24px;

      &__left {
        &__form {
          display: flex !important;
        }
      }

      &__left__header {
        display: block;
        margin-bottom: 32px;
      }
    }

    &__logo {
      margin-bottom: 32px;
    }

    &__primary-title {
      font-size: 30px;
      line-height: 38px;
    }

    &__secondary-title {
      font-size: 21px;
      margin: 0 0 32px 0;
    }

    &__tertiary-title {
      font-size: 17px;
      line-height: 24px;
    }

    &__data-text {
      font-size: 14px;
      line-height: 15px;
    }

    &__company-logos {
      margin-top: 32px;
    }
  }
}
