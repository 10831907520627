.footer-container {
  padding: 10px 40px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  width: auto;
  background: #000;

  &-complement {
    width: 400px;
  }

  &-wrap {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;

    &-left,
    &-right {
      display: flex;
      gap: 30px;
    }

    &-logo {
      height: 57px;
      width: 100px;
    }

    &-copyright-left {
      display: none;
    }

    &-routes {
      display: flex;
      align-items: center;
      gap: 20px;

      a {
        text-decoration: none;
      }

      &-text {
        color: #fff;
        font-family: 'Inter', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        cursor: pointer;
      }
    }
    &-media {
      display: flex;
      gap: 30px;
      align-items: center;
      &-logo {
        width: 25px;
      }
    }
  }
}

@media (max-width: 1040px) {
  .footer-container-complement {
    width: 150px;
  }
}
@media (max-width: 800px) {
  .footer-container {
    display: flex;
    flex-flow: column;

    .footer-container-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0;

      &-left,
      &-right {
        flex-direction: column;
        align-items: center;
        gap: 0;
      }
    }
    .footer-container-complement {
      display: none;
    }
    .footer-container-wrap-copyright {
      display: none;
    }
    .footer-container-wrap-copyright-left {
      display: block;
    }
    .footer-container-wrap-routes {
      order: 3;
    }
    .footer-container-wrap-media {
      order: 4;
    }
  }
}
