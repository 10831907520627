.entry-receive {
    background: url('../../assets/images/secondary-background.png') no-repeat;
    display: flex;
    height: 100%;
    justify-content: center;
    background-size: contain;
    background-position-x: center;
    width: 100%;

    &__container {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        &__logo {
            width: 100%;
            max-width: 442px;
            min-width: 302px;
            margin-bottom: 62px;
        }

        &__button {
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            text-transform: uppercase;
            letter-spacing: -0.07em;
            width: 267px;
            height: 43px;
            border: 3px solid #FFB600;
            background: #FFB600;
            cursor: pointer;
            border-radius: 20px;
            -webkit-padding-after: 0;
        }

        &__body {
            display: flex;
            flex-direction: column;
            align-items: center;

            &__title {
                color: #FFB600;
                font-family: 'Inter', sans-serif;
                font-size: 17.191px;
                font-style: normal;
                font-weight: 400;
                line-height: 24.831px; /* 144.444% */
                letter-spacing: 1.91px;
                text-align: center;
            }
            &__subtitle {
                color: #FFF;
                font-family: 'Inter', sans-serif;
                font-size: 30.562px;
                font-style: normal;
                font-weight: 700;
                line-height: 38.202px; /* 125% */
                text-transform: capitalize;
                text-align: center;
            }
            &__date-container {
                display: flex;
                flex-direction: row;
                align-items: center;

                &-logo {
                    margin-right: 4px;
                }

                &-text {
                    color: #FFB600;
                    font-family: 'Inter', sans-serif;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px; /* 106.667% */
                    text-transform: uppercase;
                    text-align: center;
                }
            }

            &__media {
                margin-top: 41px;
                display: flex;
                gap: 30px;
                align-items: center;
                &-logo {
                    width: 40px;
                }
            }
        }
    }
}

@media (max-width: 390px) {
    .enter {
        &__container {
            &__logo {
                width: 302px;
                height: 168px;
            }
        }
    }
}