.landing-container {
  background-image: url('../../assets/images/primary-background.png');
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;

  .landing {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__title {
      font-family: 'Inter', sans-serif;
      font-size: 30px;
      font-weight: 700;
      line-height: 36px;
      text-transform: uppercase;
      margin: 0 0 26px 0;
      color: #ffffff;
      letter-spacing: 0;
      max-width: 570px;
      text-align: center;
    }

    &__logo {
      width: 286px;
      height: 161px;
      margin-bottom: 86px;
    }

    &__buttons {
      display: flex;
      gap: 16px;
      justify-content: center;

      .accept {
        background: #ffb600;
      }

      .decline {
        background: transparent;
        color: #ffffff;
      }

      &__button {
        cursor: pointer;
        border: 3px solid #ffb600;
        width: 109px;
        height: 43px;
        border-radius: 30px;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: -0.07em;
        -webkit-padding-after: 0;
      }
    }
  }
}

@media (max-width: 390px) {
  .landing {
    &__title {
      width: 238px;
      text-align: center;
    }

    &__logo {
      width: 302px;
      margin-bottom: 114px;
      height: 168px;
    }

    &__title {
      margin-bottom: 99px;
    }
  }
}
