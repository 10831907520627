.rules {
  background: #000;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-size: cover;

  .rules-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    padding: 0px 20px;

    &-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
    }

    &-title {
      margin: 0 auto;
      color: #ffb600;
      font-family: 'Inter', sans-serif;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &-content {
      color: #fff;
      font-family: 'Inter', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      white-space: pre-wrap;
    }
  }

  @media (max-width: 420px) {
    .rules-container {
      padding: 0px 10px;
    }

    .rules-container-title {
      font-size: 24px;
    }

    .rules-container-subtitle {
      font-size: 16px;
    }
  }
}
